import { type DefaultSearchProductFieldsFragment, type ProductSortEnum } from '../../__generated__/graphql-client-types';

export function isAppointmentOnlySearchProduct(
	product: DefaultSearchProductFieldsFragment,
	isManagedPurchaseDisplayActive = false
): boolean {
	return isManagedPurchaseDisplayActive ? product.productPricingDisplay === 'SHOW_ROOM_ONLY' : product.isAppointmentOnlyBrand;
}

const ProductSort = new Set(['BEST_MATCH', 'MODEL_NUMBER', 'NEWEST', 'POPULAR', 'PRICE_HI_LOW', 'PRICE_LOW_HI', 'RATING']);

const isProductSortValue = (value?: string | null): value is ProductSortEnum => {
	return Boolean(ProductSort.has(value || ''));
};

export const convertToProductSortEnum = (value?: string | null): ProductSortEnum => {
	const upperCased = value?.toUpperCase();
	return isProductSortValue(upperCased) ? upperCased : 'BEST_MATCH';
};
